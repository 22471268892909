<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Trans ID</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ item.journalId }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Customer</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ item.customerName }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>PO#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ item.poNumber }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>INV#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ item.invoiceNumber }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Date</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ item.journalDate }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="item.listDo"
            show-expand
            :expanded.sync="expanded"
            disable-pagination
            hide-default-footer
            single-expand
            item-key="journalId"
          >
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12">PRODUCT DO</v-col>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              No
                            </th>
                            <th class="text-left">
                              Product Id
                            </th>
                            <th class="text-left">
                              Part Number
                            </th>
                            <th class="text-left">
                              Part Desc
                            </th>
                            <th class="text-left">
                              QTY
                            </th>
                            <th class="text-left">
                              Remark
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(x, index) in item.productCustomers" :key="index + 'a'">
                            <td>{{ index + 1 }}</td>
                            <td>{{ x.prodcutId }}</td>
                            <td>{{ x.partNumber }}</td>
                            <td>{{ x.partDesc }}</td>
                            <td>{{ x.qty }}</td>
                            <td>{{ x.remark }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12">PRODUCT INTERNAL</v-col>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              No
                            </th>
                            <th class="text-left">
                              Product Id
                            </th>
                            <th class="text-left">
                              Part Number
                            </th>
                            <th class="text-left">
                              Part Desc
                            </th>
                            <th class="text-left">
                              QTY
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(x, index) in item.productInternals" :key="index + 'b'">
                            <td>{{ index + 1 }}</td>
                            <td>{{ x.prodcutId }}</td>
                            <td>{{ x.partNumber }}</td>
                            <td>{{ x.partDesc }}</td>
                            <td>{{ x.qty }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-hpp",
  props: {
    item: Object,
  },
  data: () => ({
    expanded: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "DO ID",
        value: "doId",
        sortable: false,
        divider: true,
      },
      {
        text: "DO Number",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "RFS ID",
        value: "rfsId",
        sortable: false,
        divider: true,
      },
      {
        text: "RFS Number",
        value: "rfsNumber",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
