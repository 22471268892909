<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                flat
                dense
                outlined
                v-model="search"
                placeholder="Search"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.journalId="{ item }">
        <span @click="open(item)">{{ item.journalId }}</span>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="1200" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">List DO</span>
        </v-card-title>
        <v-card-text>
          <detail-hpp :item="item"></detail-hpp>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailHpp from "./DetailHpp.vue";
export default {
  name: "hpp",
  components: {
    DetailHpp,
  },
  data: () => ({
    search: "",
    dialog: false,
    item: {},
    items: [],
    headers: [
      {
        id: 1,
        text: "Trans ID",
        value: "journalId",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Customer",
        value: "customerName",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "PO#",
        value: "poNumber",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "INV#",
        value: "invoiceNumber",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Date",
        value: "journalDate",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("inventory/getHpp")
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
    open(item) {
      this.item = item;
      this.dialog = true;
    },
  },
};
</script>

<style></style>
